import VanillaModal from 'vanilla-modal'

export default class {
  constructor () {
    window.shbModalSelector = '.modal-template'

    const modal = new VanillaModal({
      modal: shbModalSelector,
      modalInner: '.modal-template__inner',
      modalContent: '.modal-template__content'
    })

    // Bind VanillaModal instance to the DOM element 'shbModalSelector' to select it later
    document.querySelector(shbModalSelector).modal = modal
  }
}
